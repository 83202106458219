export const RAMADAN_RIVE =
  'https://cdn-imgix-open.headout.com/rive/holiday-theming/ramadan/ramadan-lights-gradient.riv';
export const RAMADAN_RIVE_MWEB =
  'https://cdn-imgix-open.headout.com/rive/holiday-theming/ramadan/ramadan-lights-gradient-mweb.riv';
export const RAMADAN_HEADER_RIVE =
  'https://cdn-imgix-open.headout.com/rive/holiday-theming/ramadan/ramadan-mb-header.riv';

export const RAMADAN_CONFIG: [string[], string, string] = [
  ['AE', 'EG', 'TR'],
  '2025-03-01',
  '2025-04-02',
];
